<template>
  <div>
    <v-card class="mx-auto" max-width="344">
      <v-card-title>
      <div class="justify-center" style="margin-left:auto;margin-right:auto;" id='render'></div>
      </v-card-title>
      <v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-row align="center">
          <v-col class="d-flex" cols="12" md="6">
            <v-select :items="materialTypes" outlined dense @change="this.changeMaterialRender"/>
          </v-col>
          <v-col class="d-flex" cols="12" md="6">
            <v-select :items="materialTypes" outlined dense/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { Scene, PerspectiveCamera, WebGLRenderer, BoxGeometry, MeshStandardMaterial, Mesh, WireframeGeometry, LineSegments, AmbientLight } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'

export default {
  data: () => ({
    scene: {},
    camera: {},
    renderer: {},
    cube: {},
    controls: {},
    material: {},
    geometry: {},
    wireframeLine: {},
    materialTypes: ['wireframe', 'normal']
  }),
  mounted: function () {
    this.scene = new Scene()
    this.camera = new PerspectiveCamera(75, 250 / 250, 0.1, 1000)
    this.renderer = new WebGLRenderer()
    this.renderer.setSize(250, 250)
    this.renderer.setClearColor(0xffffff, 0.5)
    document.getElementById('render').appendChild(this.renderer.domElement)

    /* laod geo */
    const loader = new FBXLoader()
    const path = require('@/assets/fbx/a.fbx')
    loader.load(path, (fbx) => {
      fbx.scale.set(10, 10, 10)
      this.scene.add(fbx.scene)
    }, undefined, (err) => {
      console.error(err)
    })
    // this.geometry = new BoxGeometry()
    // const material = new MeshStandardMaterial({ color: 0x49ef4 })
    // this.cube = new Mesh(this.geometry, material)
    // this.scene.add(this.cube)
    this.camera.position.z = 2

    /* add controls */
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.update()

    /* add light */
    const color = 0xFFFFFF
    const intensity = 1
    const light = new AmbientLight(color, intensity)
    this.scene.add(light)
    this.animate()
  },
  methods: {
    animate: function () {
      requestAnimationFrame(this.animate)
      // this.cube.rotation.x += 0.01
      // this.cube.rotation.y += 0.01
      this.renderer.render(this.scene, this.camera)
      this.controls.update()
    },
    changeMaterialRender: function (type) {
      console.log(type)
      if (type === 'wireframe') {
        const wireframe = new WireframeGeometry(this.geometry)
        this.wireframeLine = new LineSegments(wireframe)
        this.material.transparent = true
        this.material.opacity = 0
        this.material.color = '#0x49ef4'
        this.wireframeLine.material.depthTest = false
        this.wireframeLine.material.opacity = 0.25
        this.wireframeLine.material.transparent = true
        this.scene.add(this.wireframeLine)
      }
    }
  }
}
</script>

<style>
</style>
