<template>
  <section style="background: linear-gradient(-10deg, rgba(0, 150, 136, 1)  30%, rgba(30, 30, 30, .5) 30.2%); z-index:2; ">
    <v-row justify="center">
      <h2 style="text-align:center; z-index: 3; mix-blend-mode: difference;"> {{ $t('me.gallery.title') }} </h2>
    </v-row>
    <v-row justify="center">
      <pf-separator color="rgba(10,102,194,1)"/>
    </v-row>
    <v-row justify="center" class="mt-10 mx-auto mr-16 ml-16"  >
    <v-card color="rgba(0,0,0,.3)">
    <v-slide-group v-model="model" class="pa-4" active-class="success" show-arrows>
      <v-slide-item v-for="(item, i) in $t('me.gallery.items')" :key="i">
        <v-col :md="12/$t('me.gallery.items').length" >
          <v-card elevation="2" class="mt-4" color="rgba(255,255,255,0)" justify="center"  align="center">
            <v-img  contain :src="require(`@/assets/portfolio${item.img}`)" max-height="140" max-width="300" />
            <v-row justify="center">
              <v-scale-transition>
              </v-scale-transition>
              <v-card-subtitle>{{item.text}}</v-card-subtitle>
            </v-row>
          </v-card>
        </v-col>
      </v-slide-item>
    </v-slide-group>
    </v-card>
    </v-row>
  </section>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    model: null
  }),
  mounted: function () {
    for (let item in this.$t('me.gallery.items')) {
      item = this.$t('me.gallery.items')[item]
      const image = new Image()
      image.src = require(`@/assets/portfolio${item.img}`)
    }
  }
})
</script>

<style>

</style>
