<template>
  <section class="" style="margin-top: 0px; background: linear-gradient(-10deg, rgba(221, 89, 6 , 1) 30%, rgba(30, 30, 30, .5) 30.2%), url(''); background-repeat: no-repeat; z-index:2;">
      <v-row justify="center">
          <h2 style="text-align:center; z-index: 3; mix-blend-mode: difference;"> {{ $t('me.about-me') }} </h2>
      </v-row>
      <v-row justify="center">
          <pf-separator color="rgba(10,102,194,1)"/>
      </v-row>
      <v-row justify="center" class="mt-5">
          <p align="center" class="font-weight-light" style="line-height: 1.5em; z-index: 3;  mix-blend-mode: difference;" v-html='$t("me.about")'/>
      </v-row>
      <v-row justify="center" class="mt-5">
          <v-btn class="ma-0" outlined rounded large color="primary" target="_blank" :href="require('@/assets/portfolio/' + $t('me.resume.path'))"> {{ $t("me.resume.button")}} </v-btn>
      </v-row>
  </section>
</template>

<script>
export default {}
</script>

<style>
</style>
