import axios from 'axios'

export default {
  namespaced: true,
  state: () => ({
    userprofile: {}
  }),
  mutations: {
    updateUserProfile: function (state:any, response:any) {
      state.userprofile = response.response.data.items[0]
    }
  },
  actions: { // async
    updateUserProfile ({ commit }:any, payload:any) {
      const stackoverflowId = '14880982'
      axios.get(`https://api.stackexchange.com/2.2/users/${stackoverflowId}?order=desc&site=stackoverflow&filter=unsafe`)
        .then((response) => {
          commit('updateUserProfile', { response })
        })
    }
  }
}
