<template>
    <section style="background: linear-gradient(-10deg, rgba(0, 150, 136, 1) 30%, rgba(30, 30, 30, .5) 30.2%); z-index:2;">
        <v-row justify="center">
          <h2 style="text-align:center; z-index: 3; mix-blend-mode: difference;"> {{ $t('me.projects.title') }} </h2>
        </v-row>
        <v-row justify="center">
          <pf-separator color="rgba(10,102,194,1)" />
        </v-row>
        <v-row class="mt-5" justify="center">
          <v-btn outlined rounded class="mr-10 mt-4" color="indigo" @click="filter=''"> All  </v-btn>
          <v-btn outlined rounded v-for="item in this.getTags()" @click="filter=item" active color="teal" v-bind:key="item" class="ml-2 mr-2 mt-4">{{ item }} </v-btn>
        </v-row>
        <v-row class="mt-10" justify="center" align="center">
            <v-col class="col-flex0" align="center" justify="center" md="4" v-for="item in this.projects('unity')" v-bind:key="item.title">
                <pf-cardproject :project="item" @callback="openProject(item)"/>
            </v-col>
        </v-row>
        <v-bottom-sheet v-model="sheet" inset >
          <v-sheet id="projectSheet" justify="center" align="center" class="text-center" >

        <div class="mb-10" style="padding-top: 10vh; padding-bottom: 10vh; background: linear-gradient(-10deg, rgba(0, 150, 136, 1) 30%, rgba(30, 30, 30, .5) 30.2%); z-index:2;" >
          <v-row justify="center">
            <h1> {{ this.currentProject.title }} </h1>
          </v-row>
            <v-row justify="center">
            <pf-separator color="rgba(10,102,194,1)"/>
        </v-row>
        </div>

        <v-row v-for="(row, j) in this.currentProject.content" v-bind:key="j" style="text-justify: inter-word; padding-left:5em;padding-right:5em;">
          <v-col :md="12/row.length" v-for="(col, i) in row" v-bind:key="i" style="">
            <span v-if="col.type === 'text'" style="text-indent:1em; "> <p style="text-align: center;"> {{ col.value }} </p> </span>
            <span v-if="col.type === 'title'" style="text-align: left;"> <h2 class="mt-12 mb-3">{{ col.value }}</h2> </span>
            <span v-if="col.type === 'subtitle'" style="text-align: left;text-indent:2em; color: rgba(255,255,255,0.7); text-decoration:none"> <p class="mt-12 mb-3">{{ col.value }}</p> </span>
            <span v-if="col.type === 'html'" style="" v-html="col.value" />
            <span v-if="col.type === 'list'">
              <v-list no-action>
                <v-subheader v-if="col.title"> {{ col.title }}</v-subheader>
                  <v-list-item v-for="item in col.value" :key="item.text">
                    <v-list-item-icon> <v-icon style="text-indent:2em;"> {{ item.icon }} </v-icon></v-list-item-icon>
                    <v-list-item-title style="text-align: left;" v-text="item.text"></v-list-item-title>
                  </v-list-item>
              </v-list>
            </span>
            <span v-if="col.type === 'video'" class="player-container">
              <vue-player :src="require(`@/assets/portfolio/${col.value}`)" :poster="require(`@/assets/portfolio/${col.poster}`)" :title="col.title"></vue-player>
              </span>
            <v-img contain v-if="col.type === 'img'" position="center center" style="justify:left" :src="require(`@/assets/portfolio/${col.value}`)" max-height="300px"/>
          </v-col>
        </v-row>
      </v-sheet>
      </v-bottom-sheet>
    </section>
</template>

<script>

import Vue from 'vue'
import hljs from 'highlight.js'
import marked from 'marked'
import jsHighlight from 'highlight.js/lib/languages/javascript'
import vuePlayer from '@algoz098/vue-player'

export default Vue.extend({
  data: () => ({
    filter: '',
    tags: [],
    currentProject: {},
    sheet: false
  }),
  components: {
    vuePlayer
  },
  mounted () {
    hljs.registerLanguage('javascript', jsHighlight)
    hljs.highlightAll()
    // this.preloadImages() todo
  },
  computed: {
    compiledMarkdown () {
      if (this.currentProject.content === undefined) return ''
      return marked(this.currentProject.content, {
        highlight: function (markdown) {
          return hljs.highlightAuto(markdown).value
        }
      })
    }
  },
  methods: {
    projects: function () {
      const _projects = this.$t('me.projects.projects')
      if (this.filter === '') return _projects

      return _projects.filter(project => project.tags.includes(this.filter))
    },
    getTags: function () {
      const _projects = this.$t('me.projects.projects')
      let _finalTags = []
      _projects.forEach(_project => {
        _finalTags = [...new Set([..._finalTags, ..._project.tags])]
      })
      return _finalTags
    },
    openProject: function (project) {
      this.sheet = true
      this.currentProject = project
      this.preloadImages(project)
    },
    preloadImages: function (project) {
      for (const row in this.currentProject.content) {
        for (const col in row) {
          if (col.type !== 'img') continue
          const image = new Image()
          image.src = require(`@/assets/portfolio/${col.value}`)
        }
      }
    }
  }
})
</script>

<style>
@import "~highlight.js/styles/monokai-sublime.css";

.markdown-body > p > img {
  max-width: 90%;
  max-height: 600px;
  display: flex;
  margin: auto;
}

.col-flex0 {
    flex:0;
    flex-grow:0 !important;
}

.v-bottom-sheet.v-dialog {
  overflow: auto !important;
}
</style>
