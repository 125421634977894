<template>
  <section style="background: linear-gradient(-10deg, rgba(0, 150, 136, 1) 30%, rgba(30, 30, 30, .5) 30.2%); z-index:2;">
    <v-row justify="center">
      <h2 style="text-align:center; z-index: 3; mix-blend-mode: difference;"> {{ $t('me.professionalExperiences.title') }} </h2>
    </v-row>
    <v-row justify="center">
      <pf-separator color="rgba(10,102,194,1)"/>
    </v-row>
    <v-row justify="center" align="center" class="mt-10" >
      <v-col :md="$t('me.professionalExperiences.items').length/12" v-for="(item, i) in $t('me.professionalExperiences.items')" v-bind:key="i" align="center" class="col-flex0" width="300">
        <v-card :href="item.href" target="_blank" color="rgba(0,0,0,.3)" justify="center" align="center" class="pa-0 hvr-exp">
          <div class="pt-2"></div>
          <v-img class="ma-5" :src="require(`@/assets/portfolio/logo/enterprises/${item.logo}`)" min-width="200" max-width="300"/>
          <v-card-text>
           {{ item.text }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  mounted: function () {
    for (let item in this.$t('me.professionalExperiences.items')) {
      item = this.$t('me.professionalExperiences.items')[item]
      const image = new Image()
      image.src = require(`@/assets/portfolio/logo/enterprises/${item.logo}`)
    }
  }
})
</script>

<style>

.col-flex0 {
    flex:0;
    flex-grow:0 !important;
}

@-webkit-keyframes hvr-exp {
    0% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    50% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    100% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }

  @-webkit-keyframes hvr-exp-float {
    0% {
      -webkit-filter: brightness(100%);
    }
    100% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
      -webkit-filter: brightness(150%);
    }
  }

  .hvr-exp:hover, .hvr-exp:focus, .hvr-exp:active {
    -webkit-animation-name: hvr-exp-float, hvr-exp;
    animation-name: hvr-exp-float, hvr-exp;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
  }
</style>
