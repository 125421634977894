<template>
  <div class="text-center">
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn elevation="8" :color="btnColor" v-bind="attrs" v-on="on"><v-icon aria-hidden="false" color="#a55fab">mdi-translate</v-icon> </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="locale in locales" :key="locale.code">
          <v-list-item-title><v-btn  outlined color="#0e8486" @click="switchLanguage(locale.code)">{{ locale.name }}</v-btn></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { getSupportedLocales } from '@/utils/i18n/supported-locales'
import i18n from '@/i18n'
import Vue from 'vue'
export default Vue.extend({
  created: function () {
    i18n.locale = this.$store.state.locale
  },
  props: ['btnColor'],
  methods: {
    switchLanguage: function (code) {
      this.$store.dispatch('switchlanguages', code)
    }
  },
  computed: {
    locales: function () {
      return getSupportedLocales().filter(i => i.code !== this.$store.state.locale)
    }
  }
})
</script>

<style>
</style>
