<template>
  <div class="theheader__container">
    <video
      disablePictureInPicture
      onloadedmetadata="this.muted = true"
      preload="auto"
      muted
      playsinline
      autoplay
      loop
      id="headerVideo"
    >
      <source src="@/assets/portfolio/mov/export_12-12.mp4" type="video/mp4" />
    </video>
    <div class="theheader__overlay">
      <div class="theheader__overlay__item">
        <H1><vue-typer :text='$t("title")' :repeat='0'/></H1>
        <span class="theheader__overlay__item__text">
          <vue-typer :text='$t("header-text")' :pre-type-delay='1000' :repeat='0'/>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { VueTyper } from 'vue-typer'
export default Vue.extend({
  data: () => ({}),
  components: {
    VueTyper
  }
})
</script>
<style lang="scss" scoped>
video {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  position: absolute;
}
.theheader__overlay__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  box-sizing: border-box;
}
.theheader__container {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.theheader__overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.theheader__overlay::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #000;
  opacity: 0.3;
}

.theheader__overlay__item__text {
  text-transform: uppercase;
  letter-spacing: .05em;
  font-size: calc(19.82759px + 1.26437vw);
  text-align: center;
}
</style>
