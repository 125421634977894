<template>
    <v-lazy v-model="show" :options="{ threshold: .2 }" min-height="200" transition="fade-transition" >
        <v-hover v-slot="{ hover }">
        <v-card elevation="8" width="30em" style="flex-grow:0" @click="callcallback()">
            <v-img :src="require(`@/assets/portfolio/${ $props.project.thumbnail }`)" height="200px"/>
            <v-fade-transition>
          <div justify="center" align="center"
            v-if="!hover"
            class="transition-fast-in-fast-out darken-2 v-card--reveal white--text"
            style="height: 100%;"
          >
          <div class="white--text" style="margin-top: 100px">
            <div class=".v-card__title mt-n5" style="margin: 0">{{ $props.project.title }}</div>
            <div class="v-card__subtitle mt-n5" style="margin: 0"> {{ $props.project.subtitle }}</div>
          </div>
          </div>
            </v-fade-transition>
        </v-card>
        </v-hover>
    </v-lazy>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    show: false
  }),
  mounted: function () {
    const image = new Image()
    image.src = require(`@/assets/portfolio/${this.project.thumbnail}`)
  },
  props: ['project', 'callback'],
  methods: {
    callcallback: function () {
      this.$emit('callback')
    }
  }
})
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5)
}
</style>
