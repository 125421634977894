<template>
  <v-container>
    <v-row justify="center" align="center" class="mt-n16">
      <v-col md="4"  justify="center" align="center">
        <pf-footercard class="mt-n16" :icon="$t('me.footer.email.icon')" :desc="$t('me.footer.email.text')" bg-style="background:transparent"/>
      </v-col>
      <v-col md="4" justify="center" align="center">
        <pf-footercard class="mt-n16" :icon="$t('me.footer.phone.icon')" :desc="$t('me.footer.phone.text')" bg-style="background:transparent"/>
      </v-col>
      <v-col md="4" justify="center" align="center">
         <pf-footercard class="mt-n16" :icon="$t('me.footer.linkedin.icon')" :desc="$t('me.footer.linkedin.text')" bg-style="background:transparent"/>
      </v-col>
    </v-row>
    <v-row class="mt-16">
    <v-col md=12 justify="center" align="center">
      <p class="text--secondary"> {{ $t('me.footer.text') }} -- {{ new Date().getFullYear() }} </p>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  data: () => ({
  }),
  methods: {
  }
})
</script>

<style>
</style>
