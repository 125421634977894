<template>
    <section style="background: linear-gradient(-10deg, rgba(0, 150, 136, 1) 30%, rgba(30, 30, 30, .5) 30.2%); z-index:2;">
        <v-row justify="center">
          <h2 style="text-align:center; z-index: 3; mix-blend-mode: difference;"> {{ $t('me.tools-used.title') }} </h2>
        </v-row>
        <v-row justify="center">
          <pf-separator color="rgba(10,102,194,1)"/>
        </v-row>
        <v-row justify="center" class="mt-10" >
          <v-card color="rgba(0,0,0,.3)">
              <v-card-text>
                <v-row>
                  <v-col :md="12/$t('me.tools-used.tools').length" v-for="item in $t('me.tools-used.tools')" v-bind:key="item.name" >
                  <pf-toolused :thumbnail-path="item.thumbnail" :tooltip="item.name"/>
                  </v-col>
                </v-row>
              </v-card-text>
          </v-card>
        </v-row>
        <v-row justify="center" class="mt-10" >
          <v-card color="rgba(0,0,0,.3)">
              <v-card-text>
                <v-row>
                  <v-col :md="12/$t('me.tools-used.tools2').length" v-for="item in $t('me.tools-used.tools2')" v-bind:key="item.name" >
                    <pf-toolused :thumbnail-path="item.thumbnail" :tooltip="item.name" />
                  </v-col>
                </v-row>
              </v-card-text>
          </v-card>
        </v-row>
    </section>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
})
</script>

<style>
.gradient-light:hover {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0) 50%, rgba(255,255,255,.1) 100%);
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 70%);
}
</style>
