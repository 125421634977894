import Vue from 'vue'

import HeaderComponent from '@/components/header/TheHeader.component.vue'

/* select locale */
import SelectLocale from '@/components/select-locale/TheSelectLocale.component.vue'
import AppBar from '@/components/app-bar/TheAppBar.component.vue'

/* ThreeCard */
import ThreeCard from '@/components/three-card/ThreeCard.vue'

/* ProfileCard */
import ProfileCard from '@/components/profile-card/ProfileCard.component.vue'
import TheHeaderProfileCards from '@/components/profile-card/TheHeaderProfileCards.component.vue'

/* Presentation Card */
import ThePresentationCard from '@/components/presentation-card/ThePresentationCard.component.vue'
import CertificateCardComponent from '@/components/presentation-card/CertificateCard.component.vue'

/* Separator */
import Separator from '@/components/separator/Separator.component.vue'

/* Project */
import CardProject from '@/components/projects/CardProject.component.vue'
import TheProjectCards from '@/components/projects/TheProjectCards.component.vue'

/* Tools-Used */
import ToolUsed from '@/components/tools-used/ToolUsed.component.vue'
import TheToolsUsedCard from '@/components/tools-used/TheToolsUsedCard.component.vue'

/* Gallery */
import TheGallery from '@/components/gallery/TheGallery.component.vue'

/* Professional experience */
import TheProfessionalExperience from '@/components/professional-experience/TheProfessionalExperience.component.vue'

/* Footer */
import TheFooter from '@/components/footer/TheFooter.component.vue'
import FooterCard from '@/components/footer/FooterCard.component.vue'

Vue.component('pf-header', HeaderComponent)
Vue.component('pf-locale', SelectLocale)
Vue.component('pf-appbar', AppBar)
Vue.component('pf-threecard', ThreeCard)
Vue.component('pf-profilecard', ProfileCard)
Vue.component('pf-headerprofilecards', TheHeaderProfileCards)
Vue.component('pf-presentationcard', ThePresentationCard)
Vue.component('pf-certificatecard', CertificateCardComponent)
Vue.component('pf-separator', Separator)
Vue.component('pf-cardproject', CardProject)
Vue.component('pf-cardprojects', TheProjectCards)
Vue.component('pf-toolsusedcard', TheToolsUsedCard)
Vue.component('pf-toolused', ToolUsed)
Vue.component('pf-gallery', TheGallery)
Vue.component('pf-professionalexperience', TheProfessionalExperience)
Vue.component('pf-footer', TheFooter)
Vue.component('pf-footercard', FooterCard)
