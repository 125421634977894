<template>
    <v-card :href="$props.href" target="_blank" class="mx-auto repocard hvr-bob" max-width="400" min-width="200"  :style="$props.bgStyle" >
      <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)"
      >
      <v-toolbar-title class="text-h6 white--text pl-0"> {{ $props.title }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon color="white">{{ $props.icon }}</v-icon>
      </v-app-bar>

      <v-card-title>
        <v-avatar size="42">
          <img
            class="white--text align-end"
            :src="$props.logoPath">
        </v-avatar>
          <p class="ml-3 white--text"> {{ $props.name }} </p>
        </v-card-title>

    <v-card-text align="center" class="white--text">
     <div v-html="$props.desc"/>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  props: ['name', 'desc', 'logoPath', 'icon', 'title', 'bgStyle', 'href']
})
</script>

<style lang="css">
.repocard {
  opacity: 1;
}

@-webkit-keyframes hvr-bob {
    0% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    50% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    100% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }

  @keyframes hvr-bob {
    0% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    50% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    100% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }

  @-webkit-keyframes hvr-bob-float {
    100% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }
  @keyframes hvr-bob-float {
    100% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }

  .hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
    -webkit-animation-name: hvr-bob-float, hvr-bob;
    animation-name: hvr-bob-float, hvr-bob;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
  }
</style>
