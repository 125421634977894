import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/i18n'
/* Modules */
import GithubApi from './modules/github-api'
import StackOverflowApi from './modules/stackoverflow-api'
import LinkedinApi from './modules/linkedin-api'

import createPersistedState from 'vuex-persistedstate'
const persistedStateOptions = {
  storage: window.sessionStorage
}
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '0.0.0',
    name: 'MyApp',
    locale: 'en'
  },
  mutations: {
    switchLanguages: function (state:any, locale:any) {
      state.locale = locale.payload
      i18n.locale = locale.payload
    }
  },
  actions: {
    switchlanguages ({ commit }: any, payload:any) {
      commit('switchLanguages', { payload })
    }
  },
  modules: {
    githubApi: GithubApi,
    stackOverflowApi: StackOverflowApi,
    linkedinApi: LinkedinApi
  },
  plugins: [createPersistedState(persistedStateOptions)]
})
