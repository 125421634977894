<template>
    <v-card class="mx-auto" flat max-width="400" min-width="200" :style="$props.bgStyle">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
      <v-toolbar-title class="text-h6 white--text pl-0"> {{ $props.title }} </v-toolbar-title>

      </v-app-bar>
    <v-card-text align="center" class="white--text">
        <div v-html="$props.desc"/>
        <v-icon small class="mr-2" color="white"> {{ $props.icon }} </v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  props: ['desc', 'icon', 'title', 'bgStyle']
})
</script>
