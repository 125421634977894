import axios from 'axios'

export default {
  namespaced: true,
  state: () => ({
    repositories: [],
    userprofile: {}
  }),
  mutations: {
    updateRepositories: function (state:any, response:any) {
      state.repositories = response.response.data
      // and sort by date
      state.repositories = state.repositories.sort((a:any, b:any) => (Date.parse(a.created_at) < Date.parse(b.created_at)) ? 1 : -1)
      console.log(state.repositories)
    },
    updateUserProfile: function (state:any, response:any) {
      state.userprofile = response.response.data
    }
  },
  actions: { // async
    updateRepositories ({ commit }:any, payload:any) {
      const githubUsername = 'OlivierArgentieri'
      axios.get(`https://api.github.com/users/${githubUsername}/repos?page=1&per_page=999`)
        .then((response) => {
          commit('updateRepositories', { response })
        })
    },
    updateUserProfile ({ commit }:any, payload:any) {
      const githubUsername = 'OlivierArgentieri'
      axios.get(`https://api.github.com/users/${githubUsername}`)
        .then((response) => {
          commit('updateUserProfile', { response })
        })
    }
  }
}
