<template>
  <div class="mr-2 ml-2">
    <v-tooltip bottom  v-model="show">
      <span>{{ $props.tooltip }}</span>
      <template v-slot:activator="{ on, attrs }">
      <v-img class="hvr-logo" contain :src="require(`@/assets/portfolio${$props.thumbnailPath}`)" max-height='45' v-bind="attrs" v-on="on"/>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    show: false
  }),
  props: ['tooltip', 'thumbnailPath'],
  mounted: function () {
    const image = new Image()
    image.src = this.thumbnailPath
  }
})
</script>

<style>

@-webkit-keyframes hvr-logo {
    0% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    50% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    100% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }

  @-webkit-keyframes hvr-logo-float {
    100% {
      -webkit-transform: translateY(-8px);
      -webkit-filter: brightness(150%);

      transform: translateY(-8px);
    }
  }

  .hvr-logo:hover, .hvr-bologob:focus, .hvr-logo:active {
    -webkit-animation-name: hvr-blogoob-float, hvr-logo;
    animation-name: hvr-logo-float, hvr-logo;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
  }
</style>
