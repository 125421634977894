<template>
  <div class="separator" :style="cssVars"/>
</template>

<script>

import Vue from 'vue'
export default Vue.extend({
  props: ['color'],
  computed: {
    cssVars () {
      return {
        '--color': this.color
      }
    }
  }
})
</script>

<style scoped>

.separator {
  width: 50px;
  height: 0;
  border: 1px solid var(--color);
  margin: 3px;
  display: inline;
}

</style>
