<template>
  <div class="mx-auto mt-n2">
    <v-row style="position:relative; z-index: 1">
      <v-col md="4">
        <pf-profilecard class="mt-n12"
        v-if="$store.state.githubApi.userprofile"
        :name="$store.state.githubApi.userprofile.login"
        :desc='$store.state.githubApi.userprofile.bio'
        :logo-path="$store.state.githubApi.userprofile.avatar_url"
        parallax-path="/logo/GitHub-Logo_edit.png"
        icon="mdi-github"
        title="GitHub"
        bg-style="background: rgb(4,13,33);background: linear-gradient(194deg, rgba(4,13,33,1) 0%, rgba(0,0,0,0) 60%);"
        :href="$store.state.githubApi.userprofile.html_url"/>

        <pf-profilecard
        v-else name="TemplateName"
        desc='You should not see this :['
        logo-path=""/>
      </v-col>

      <v-col md="4">
        <pf-profilecard class="mt-n12"
        v-if="$store.state.stackOverflowApi.userprofile"
        :name="$store.state.stackOverflowApi.userprofile.display_name"
        :desc='$store.state.stackOverflowApi.userprofile.about_me'
        :logo-path="$store.state.stackOverflowApi.userprofile.profile_image"
        icon="mdi-stack-overflow"
        title="StackOverflow"
        bg-style="background: rgb(185,88,7);background: linear-gradient(194deg, rgba(185,88,7,1) 0%, rgba(0,0,0,0) 60%);"
        :href="$store.state.stackOverflowApi.userprofile.link"
        />

        <pf-profilecard
        v-else name="TemplateName"
        desc='You should not see this :['
        logo-path=""/>
      </v-col>
      <v-col md="4">
        <pf-profilecard class="mt-n12"
        v-if="$store.state.linkedinApi.userprofile"
        :name="$store.state.linkedinApi.userprofile.display_name"
        :desc='$store.state.linkedinApi.userprofile.description'
        :logo-path="require('@/assets/portfolio/photo/me.jpg')"
        parallax-path="/logo/GitHub-Logo.png"
        title="Linkedin"
        icon="mdi-linkedin"
        bg-style="background: rgb(10,102,194);background: linear-gradient(194deg, rgba(10,102,194,1) 0%, rgba(0,0,0,0) 60%);"
        :href="$store.state.linkedinApi.userprofile.link"/>

        <pf-profilecard
        v-else name="TemplateName"
        desc='You should not see this :['
        logo-path=""/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  mounted: function () {
    this.$store.dispatch('githubApi/updateUserProfile')
    this.$store.dispatch('stackOverflowApi/updateUserProfile')
  }
})
</script>

<style>
.textBehind {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -ms-transform: translateY(-30%);
  transform: translateY(-30%) rotate(-10deg);
  text-align: center;
  letter-spacing: 25%;
  word-spacing: 1em;
  font-size: 10em;
  opacity: 0;
}

.shiftTop {
  display: flex;
  transform: translateY(-30%);
}
</style>
