<template>
  <div>
    <v-app-bar dense dark :color="bg" class="fixed">
      <v-toolbar-title>{{$t('title')}} </v-toolbar-title>
      <v-spacer></v-spacer>
      <pf-locale :btn-color="bg" />
    </v-app-bar>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    bg: '',
    scroll: 0
  }),
  mounted: function () {
    window.onscroll = () => {
      this.changeColor()
    }
  },
  methods: {
    changeColor () {
      this.scroll = window.scrollY / window.innerHeight
      if (this.scroll > 0.2) {
        this.bg = 'dark'
      } else {
        this.bg = 'transparent'
      }
    }
  }
})
</script>

<style scoped>
.fixed {
    position: fixed;
    z-index: 99;
}
</style>
