
import Vue from 'vue'
import Doc from '../components/Doc/Doc.vue'

export default Vue.extend({
  name: 'aaaaa',

  components: {
    Doc
  }
})
