import axios from 'axios'

export default {
  namespaced: true,
  state: () => ({
    userprofile: {
      display_name: 'Olivier Argentieri',
      profile_image: 'https://media-exp1.licdn.com/dms/image/C4D03AQFydQvaowtymA/profile-displayphoto-shrink_200_200/0/1567012708395?e=1634169600&v=beta&t=gp4qi9VJ-Z1kXr7Y6UmVQmwQaqBiG8WqNyU7MV3xNOY',
      description: 'Student at Artfx TD Pipeline / Realtime',
      link: 'https://www.linkedin.com/in/olivier-a-707ab9151'
    }
  }),
  mutations: {
    updateUserProfile: function (state:any, response:any) {
      console.log(response.response.data.items[0])
      state.userprofile = response.response.data.items[0]
    }
  },
  actions: { // async
    updateUserProfile ({ commit }:any, payload:any) {
      const LinkedinId = 'olivier-a-707ab9151'
      axios.get('')
        .then((response) => {
          commit('updateUserProfile', { response })
        })
    }
  }
}
